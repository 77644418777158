<template>
    <div ref="challenge" class="flex flex-col md:flex-row max-w-screen-lg py-20 mx-auto gap-6 lg:gap-10 items-center px-4">
        <object 
            aria-label="smoxy challenge image"
            class="flex-1 bg-[#7E1EF8] bg-gradient-to-r from-[#571EF8] rounded-2xl drop-shadow-xl relative w-full items-center flex flex-col py-10 gap-6">
            <img
                alt="Trophy icon"
                src="@/assets/icon_trophy.svg"
                class="w-44 h-44 md:w-32 md:h-32  mx-auto"
                width="111" height="111"
                />

            <img
                alt="Ranking image"
                src="@/assets/ranking.svg"
                class="mx-auto w-60 md:w-58"
                width="344" height="171"
                />

        </object>
        <div class="pt-6 md:pt-0 px-4 md:px-0 flex-1 w-full">
            <p class="text-[#7E1EF8]">{{$t('home.features.challenge.title')}}</p>
            <h3 class="text-3xl pr-4 font-bold font-owners text-[#290B50]">{{$t('home.features.challenge.headline')}}</h3>
            <p class="pt-4">{{$t('home.features.challenge.body')}}</p>
        </div>   
    </div>
</template>